import React from "react";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },

  dialogContent: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1em" },

  buttonProgress: { color: green[500] },

  messageTextContainer: { textAlign: "center" },
}));

const LoadingModal = ({ open, titleText, messageText }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          <Typography>{titleText}</Typography>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <CircularProgress size={24} className={classes.buttonProgress} />
          <Typography className={classes.messageTextContainer}>{messageText}</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingModal;