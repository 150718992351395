import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useSocketLogin } from "../../context/Socket/SocketContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useAuth = () => {
	// 	***************
	// 	** Variables **
	// 	***************
	const history = useHistory();
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});
	const { handleSocketLogin, handleSocketLogout, handleUserCallback } = useSocketLogin();



	// 	******************
	// 	** Interceptors **
	// 	******************
	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			if (token) {
				config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
				try {
					config.headers["tenantId"] = JSON.parse(atob(token.split('.')[1])).tenantId;
					config.headers["groupId"] = JSON.parse(atob(token.split('.')[1])).groupId;
				}
				catch (exception) {
					console.log("- Token Interceptors Exception:", exception)
				}
				setIsAuth(true);
			}
			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;

				const { data } = await api.post("/auth/refresh_token");
				
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					api.defaults.headers.tenantId = JSON.parse(atob(data.token.split('.')[1])).tenantId;
					api.defaults.headers.groupId = JSON.parse(atob(data.token.split('.')[1])).groupId;	
				}
				
				return api(originalRequest);
			}
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}
			return Promise.reject(error);
		}
	);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const token = localStorage.getItem("token");
		(async () => {
			if (token) {
				try {
					const { data } = await api.post("/auth/refresh_token");

					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					api.defaults.headers.tenantId = JSON.parse(atob(data.token.split('.')[1])).tenantId;
					api.defaults.headers.groupId = JSON.parse(atob(data.token.split('.')[1])).groupId;

					setIsAuth(true);
					setUser(data.user);

					handleSocketLogin(api.defaults.headers.tenantId, data.user.id);
				} catch (error) {
					console.log("Use Auth Use Effect 1 Error:", error);
					toastError(error);
				}
			}
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleUserCallback({ user, setUser });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleLoginGroup = async userData => {
		setLoading(true);

		try {
			const { data } = await api.post("/auth/loginGroup", userData);

			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");

			api.defaults.headers.groupId = data.groupId;
			api.defaults.headers.tenantId = data.tenantId;
			api.defaults.headers.email = userData.email;
			
			setLoading(false);
			history.push("/login");
		} catch (error) {
			console.log("Handle Login Group Error:", error);
			toastError(error);
			setLoading(false);
		}
	};

	const handleLogin = async userData => {
		setLoading(true);

		try {
			const { data } = await api.post("/auth/login", userData);
			localStorage.setItem("token", JSON.stringify(data.token));

			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			api.defaults.headers.tenantId = JSON.parse(atob(data.token.split('.')[1])).tenantId;
			api.defaults.headers.groupId = JSON.parse(atob(data.token.split('.')[1])).groupId;

			setUser(data.user);
			setIsAuth(true);


			handleSocketLogin(api.defaults.headers.tenantId, data.user.id);

			history.push("/tickets");

			setLoading(false);
		} catch (error) {
			console.log("Handle Login Error:", error);
			toastError(error);
			setLoading(false);
		}
	};

	const handleLogout = async () => {
		setLoading(true);

		try {
			handleSocketLogout(api.defaults.headers.tenantId, user.id);

			await api.delete("/auth/logout");

			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");

			api.defaults.headers.Authorization = undefined;
			api.defaults.headers.tenantId = undefined;
			api.defaults.headers.groupId = undefined;

			setLoading(false);

			localStorage.setItem("selectedTicketContactId", "-1");
			history.push("/loginGroup");
		} catch (error) {
			console.log("Handle Logout Error:", error);
			toastError(error);
			setLoading(false);
		}
	};



	// 	************
	// 	** Return **
	// 	************
	return { isAuth, user, loading, handleLoginGroup, handleLogin, handleLogout };
};

export default useAuth;
