import React, { useCallback, useEffect, useState } from "react";
import {
	Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    width: 275,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.default,
  },

  list: {
    width: 275,
    height: 230,
    backgroundColor: theme.palette.background.quotedPaper,
    overflow: "auto",
  },

  transferListButton: {
    margin: theme.spacing(0.50, 0),
    backgroundColor: theme.palette.background.quotedPaper,
  },
}));

const TransferList = ({
  nonSelectedItemsList, setNonSelectedItemsList, selectedItemsList, setSelectedItemsList,
  searchParam = "", searchAttribute = [], searchSubAttribute = ""
}) => {
  //  ***************
  //  ** Operators **
  //  ***************
  const notOperator = (elementA, elementB) => {
    return elementA.filter(value => elementB.indexOf(value) === -1);
  };
  
  const intersectionOperator = (elementA, elementB) => {
    return elementA.filter(value => elementB.indexOf(value) !== -1);
  };
  
  const unionOperator = (elementA, elementB) => {
    return [...elementA, ...notOperator(elementB, elementA)];
  };



  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFilterEnabled = searchParam.length > 0 || searchAttribute.length > 0 || searchSubAttribute.length > 0;

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersectionOperator(checked, left);
  const rightChecked = intersectionOperator(checked, right);

  const ITEMS_BATCH_SIZE = 50;
  const [filteredLeft, setFilteredLeft] = useState([]);
  const [filteredRight, setFilteredRight] = useState([]);



  //  ***************
  //  ** Callbacks **
  //  ***************
  const filterListCallback = useCallback((item) => {
    return (
      (
        item.description.toLowerCase().includes(searchParam)
        || item.subDescription.toLowerCase().includes(searchParam)
      )
      && (
        searchAttribute.length === 0
        || item.attribute.some(attribute => searchAttribute.includes(attribute.id))
      )
      && (
        searchSubAttribute === ""
        || item.subAttribute === searchSubAttribute
      )
    );
  }, [searchParam, searchAttribute, searchSubAttribute]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    setLeft(nonSelectedItemsList);
    setFilteredLeft(
      nonSelectedItemsList
        .filter(item => filterListCallback(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setRight(selectedItemsList);
    setFilteredRight(
      selectedItemsList
        .filter(item => filterListCallback(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );
  }, [nonSelectedItemsList, selectedItemsList, searchParam, searchAttribute, searchSubAttribute, filterListCallback]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersectionOperator(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) setChecked(notOperator(checked, items));
    else setChecked(unionOperator(checked, items));
  };

  const filterList = (item) => {
    return (
      (
        item.description.toLowerCase().includes(searchParam)
        || item.subDescription.toLowerCase().includes(searchParam)
      )
      && (
        searchAttribute.length === 0
        || item.attribute.some(attribute => searchAttribute.includes(attribute.id))
      )
      && (
        searchSubAttribute === ""
        || item.subAttribute === searchSubAttribute
      )
    );
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    newRight.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description));

    const newLeft = notOperator(left, leftChecked);

    setRight(newRight);
    setFilteredRight(
      newRight
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setLeft(newLeft);
    setFilteredLeft(
      newLeft
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setChecked(notOperator(checked, leftChecked));

    setSelectedItemsList(newRight);
    setNonSelectedItemsList(newLeft);
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    newLeft.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description));

    const newRight = notOperator(right, rightChecked);

    setLeft(newLeft);
    setFilteredLeft(
      newLeft
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setRight(newRight);
    setFilteredRight(
      newRight
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );
    
    setChecked(notOperator(checked, rightChecked));

    setSelectedItemsList(newRight);
    setNonSelectedItemsList(newLeft);
  };

  const handleAllRight = () => {
    const newRight = right.concat(left);
    newRight.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description));

    setRight(newRight);
    setFilteredRight(
      newRight
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setLeft([]);
    setFilteredLeft([]);

    setSelectedItemsList(newRight);
    setNonSelectedItemsList([]);
  };

  const handleAllLeft = () => {
    const newLeft = left.concat(right);
    newLeft.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description));
    
    setLeft(newLeft);
    setFilteredLeft(
      newLeft
        .filter(item => filterList(item))
        .slice(0, ITEMS_BATCH_SIZE)
    );

    setRight([]);
    setFilteredRight([]);

    setSelectedItemsList([]);
    setNonSelectedItemsList(newLeft);
  };

  const handleScroll = (event, isSelectedList) => {
    if (isSelectedList && filteredRight.length === right) return;
    if (!isSelectedList && filteredLeft.length === left) return;

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      if (isSelectedList) {
        setFilteredRight(previousValue => {
          return [
            ...previousValue,
            ...right
              .filter(item => filterList(item))
              .slice(previousValue.length, previousValue.length + ITEMS_BATCH_SIZE)
          ];
        });
      }

      else {
        setFilteredLeft(previousValue => {
          return [
            ...previousValue,
            ...left
              .filter(item => filterList(item))
              .slice(previousValue.length, previousValue.length + ITEMS_BATCH_SIZE)
          ];
        });
      }
    }
  };



  //  ********************
  //  ** Sub Components **
  //  ********************
  const customList = (title, items, isSelectedList) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${i18n.t("transferList.selected")}`}
      />

      <Divider />

      <List
        className={classes.list}
        onScroll={(event) => handleScroll(event, isSelectedList)}
        component="div"
        role="list"
        dense
      >
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem key={value} role="listItem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple />
              </ListItemIcon>
              
              <ListItemText id={labelId} primary={value.description} secondary={value.subDescription} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );



  //  ************
  //  ** Return **
  //  ************
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        {customList(i18n.t("transferList.choices"), filteredLeft, false)}
      </Grid>
      
      <Grid item>
        <Grid container direction={isSmallScreen ? "row" : "column"} alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.transferListButton}
            onClick={handleAllRight}
            disabled={left.length === 0 || isFilterEnabled}
          >
            {isSmallScreen ? "⇓" : "≫"}
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.transferListButton}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            {isSmallScreen ? "↓" : ">"}
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.transferListButton}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            {isSmallScreen ? "↑" : "<"}
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.transferListButton}
            onClick={handleAllLeft}
            disabled={right.length === 0 || isFilterEnabled}
          >
            {isSmallScreen ? "⇑" : "≪"}
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        {customList(i18n.t("transferList.chosen"), filteredRight, true)}
      </Grid>
    </Grid>
  );
};

export default TransferList;