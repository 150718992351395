import React, { useCallback, useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isSameDay, parseISO, format } from "date-fns";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import Lottie from "react-lottie";

import { Videoplayer } from "@openabos/react-circular-player";
import "@openabos/react-circular-player/dist/cjs/index.css";
import "../../styles/react-circular-player-new-styles.css";
import "../../styles/react-three-dots-elastic.css";

import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  FilterTiltShiftOutlined,
  FormatListBulletedOutlined,
  GetApp,
  MissedVideoCallRounded,
  PhoneMissedRounded,
  RecordVoiceOverRounded,
  ReplyOutlined,
  StarOutlined,
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import extractDayMonthYearFromStringDateTime from "../../utils/extractDayMonthYearFromStringDateTime";
import toastError from "../../errors/toastError";

import AdsMessage from "../AdsMessage";
import Audio from "../Audio";
import ButtonPreview from "../ButtonPreview";
import ConfirmationModal from "../ConfirmationModal";
import EventPreview from "../EventPreview";
import EventPreviewQuotedMessage from "../EventPreviewQuotedMessage";
import getParentNodeByClassName from "../../utils/getParentNodeByClassName";
import GroupInvitePreview from "../GroupInvitePreview";
import ListPreview from "../ListPreview";
import LocationPreview from "../LocationPreview";
import MessageBodyWrapper from "../MessageBodyWrapper";
import MessageEditHistoryModal from "../MessageEditHistoryModal";
import MessageNotAvailable from "../MessageNotAvailable";
import MultiVcardPreview from "../MultiVcardPreview";
import ModalImageCors from "../ModalImageCors";
import PhoneNumberMessageOptionsMenu from "../PhoneNumberMessageOptionsMenu";
import PollPreview from "../PollPreview";
import PollPreviewQuotedMessage from "../PollPreviewQuotedMessage";
import PollVotesModal from "../PollVotesModal";
import VcardPreview from "../VcardPreview";
import ViewOnceMessage from "../ViewOnceMessage";
import ViewOnceRepliedMessage from "../ViewOnceRepliedMessage";

import saveFile from "../../utils/saveFile";
import useWhatsApps from "../../hooks/useWhatsApps";

import ChatLottie from "../../assets/lottie/chat.json";

const useStyles = makeStyles((theme) => ({
  quotedMessageAnchor: { textDecoration: "none", color: theme.palette.text.primary, },

  contactAvatarContainer: { height: "100%", paddingTop: "1em", },

  contactAvatar: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
    marginLeft: "10px",
    cursor: "pointer",
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },

  callWrapper: { display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", },
  missedCall: { color: "red", fontSize: "18px", },
  horizontalFlip: { rotate: "y 180deg", fontSize: "20px", },
  forwardedMessage: { fontSize: "12px", color: "#998", display: "flex", alignItems: "center", gap: "5px", },
  buttonListFooter: { fontSize: "12px", color: theme.palette.text.primary, },
  messagesListWrapper: { padding: "5px 0 5px 0", overflow: "hidden", position: "relative", display: "flex", flexDirection: "column", flexGrow: 1, },

  messagesList: {
    borderRadius: "20px",
    backgroundImage: theme.backgroundImage,
    display: "flex",
    flexDirection: "column",

    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,

    [theme.breakpoints.down("sm")]: { paddingBottom: "90px", },
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  reactionList: {
    position: "absolute",
    bottom: "-18px",
    display: "flex",
    flexDirection: "row",
    zIndex: "9",
    gap: "2px",
  },

  reactionListSticker: {
    position: "absolute",
    bottom: "0px",
    left: "5px",
    display: "flex",
    flexDirection: "row",
    zIndex: "9",
    gap: "2px",
  },

  reactionSpan: {
    borderRadius: "20px",
    padding: "3px 3px 3px 3px",
    backgroundColor: theme.palette.background.paper,
    fontSize: "15px",
    userSelect: "none",
    "&:hover": { filter: "brightness(0.92)" },
  },

  containerMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    "&:target": { animation: '$targetMessage 2s linear', borderRadius: "5px" },
  },

  "@keyframes targetMessage": {
    "0%": { backgroundColor: "transparent", },
    "50%": { backgroundColor: theme.palette.primary.rgbaPastel, },
    "100%": { backgroundColor: "transparent", },
  },

  containerMessageLeft: { justifyContent: "left", marginLeft: "-15px", },
  containerMessageRight: { justifyContent: "right", marginRight: "-15px", },

  messageLeftSticker: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",

    whiteSpace: "pre-wrap",
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.quotedPaper,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: { flex: "none", width: "4px", backgroundColor: "#6bcbef", },

  messageRightSticker: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",

    whiteSpace: "pre-wrap",
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.primary.messageBox,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  internalMessage: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",

    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.internalMessage,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: theme.palette.primary.quotedMessageBox,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedSideColorRight: { flex: "none", width: "4px", backgroundColor: "#35cd96", },
  messageContactName: { display: "flex", color: "#6bcbef", fontWeight: 500, },
  textContentItem: { overflowWrap: "break-word", },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  messageMediaFullScreen: {
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  deletedMediaMessage: { opacity: 0.7, filter: "grayscale(80%)", },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: theme.palette.text.primary,
    userSelect: "none",
    
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "2px",
  },

  editedMessage: {
    fontStyle: "italic",
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" }
  },

  timestampStickerLeft: {
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: theme.palette.background.paper,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "2px",
  },

  timestampStickerRight: {
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: theme.palette.primary.messageBox,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "3px",
  },

  timeStampStar: { fontSize: 12, },

  notificationSpan: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "75%",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: theme.palette.background.paper,
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: { color: "#808888", padding: 8, alignSelf: "center", marginLeft: "0px", },
  ackIcons: { fontSize: 18, verticalAlign: "middle", marginLeft: 4, },
  deletedIcon: { fontSize: 18, verticalAlign: "middle", marginRight: 4, },
  ackDoneAllIcon: { color: theme.palette.secondary.ackCheck, fontSize: 18, verticalAlign: "middle", marginLeft: 4, },

  downloadMedia: {
    display: "flex",
    flexDirection: "column",
    transition: "all 0.3s",
    gap: "1em",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  lastMessageTicketContainer: {
    display: "flex",
    flexDiretion: "row",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
  },

  lastMessageTicketDivider: { width: "100%", height: "1px", },
  lastMessageTicketSpan: { width: "85%", textAlign: "center", },

  stickerMessage: {
    maxHeight: 225,
    maxWidth: 225,
    borderRadius: 5,
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: { maxHeight: 150, maxWidth: 150, },
  },

  deletedStickerMessage: { opacity: 0.7, filter: "grayscale(80%)", },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  },

  //  **************************
  //  ** No Message Container **
  //  **************************
  noMessageContainer: {
    width: "100.00%",
    overflowY: "hidden",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  noMessagesText: { padding: 8, alignSelf: "center", marginLeft: "0px", },
}));

const MessagesListChatView = ({ open, contactId, whatsappId }) => {  
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { user: { profile: userProfile, id: userId, whatsapp: userWhatsapp } } = useContext(AuthContext);
  const currentWhatsappId = useRef(whatsappId);

  const [noMessagesFlag, setNoMessagesFlag] = useState(true);
  const [messagesList, setMessagesList] = useState([]);
  const [filesExistenceDictionary, setFilesExistenceDictionary] = useState({});

  const lastMessageRef = useRef();
  const currentAudioRef = useRef();

  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const [messageEditHistoryModalOpen, setMessageEditHistoryModalOpen] = useState(false);
  const [selectedMessageEditHistory, setSelectedMessageEditHistory] = useState(null);

  const [pollVotesModalOpen, setPollVotesModalOpen] = useState(false);
  const [selectedPollVotesMessage, setSelectedPollVotesMessage] = useState([]);

  const [openPrivateChatConversationModal, setOpenPrivateChatConversationModal] = useState(false);
  const [selectedContactPrivateChat, setSelectedContactPrivateChat] = useState(null);

  const { whatsApps } = useWhatsApps();
  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

  const [selectedPhoneNumberMessage, setSelectedPhoneNumberMessage] = useState(null);
  const [phoneNumberMessageOptionsMenuAnchorEl, setPhoneNumberMessageOptionsMenuAnchorEl] = useState(null);
  const phoneNumberMessageOptionsMenuOpen = Boolean(phoneNumberMessageOptionsMenuAnchorEl);



  //  ************
  //  ** Lottie **
  //  ************
  const chatLottieOptions = {
    animationData: ChatLottie,
    loop: true,
    background: "transparent",
    speed: 1.00,
    direction: 1.00,
    playMode: "normal",
    autoplay: true,
  };



  //  ***************
  //  ** Callbacks **
  //  ***************
  const scrollToBottomCallback = useCallback((smoothBehavior) => {
    if (smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    else if (!smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  }, []);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (!open) {
      setHasMore(true);
      setPageNumber(1);
      setNoMessagesFlag(false);
      setMessagesList([]);
      setFilesExistenceDictionary({});
    }
  }, [open]);

  useEffect(() => {
    if (userWhatsapp?.id) setSelectedWhatsappId(userWhatsapp.id);
    else if (whatsApps.length === 1) setSelectedWhatsappId(whatsApps[0].id);
  }, [userWhatsapp, whatsApps]);
  
  useEffect(() => {
    setPageNumber(1);
    if (whatsappId !== undefined && whatsappId !== null) currentWhatsappId.current = whatsappId;
  }, [whatsappId]);

  useEffect(() => {
    if (contactId && whatsappId) {
      const fetchMessages = async () => {
        try {
          setMessagesList([]);
          const { data } = await api.get(`/chat/${contactId}`, { params: { pageNumber, whatsappId } });
          
          if (data.info) {
            setNoMessagesFlag(true);
          }
          else {
            setNoMessagesFlag(false);
            setHasMore(data.hasMore);

            setMessagesList(previousValue => {
              if (data.messages) return [...data.messages, ...previousValue];
              return [...previousValue];
            });

            setFilesExistenceDictionary({ ...filesExistenceDictionary, ...data.filesExistenceDictionary});

            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom();
            }
          }
        } catch (exception) {
          console.log("Messages List Chat View Use Effect Exception:", exception);
          toastError(exception);
        }
      };
  
      fetchMessages();
    }

    if (whatsappId === "") {
      setHasMore(true);
      setPageNumber(1);
      setNoMessagesFlag(false);
      setMessagesList([]);
      setFilesExistenceDictionary({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, whatsappId]);
  
  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  
    return () => {
      document.removeEventListener("fullscreenchange", setIsFullscreen(!!document.fullscreenElement));
    };
  }, []);
  
  useEffect(() => {
    open && setTimeout(() => { scrollToBottomCallback(); }, 100);
  }, [open, whatsappId, scrollToBottomCallback]);


  
  //  ***************
  //  ** Functions **
  //  ***************
  const scrollToBottom = (smoothBehavior) => {
    if (smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
    else if (!smoothBehavior && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };
  
  const loadMessages = async (pageNumber) => {
    setLoading(true);

    const { data } = await api.get(`/chat/${contactId}`, { params: { pageNumber, whatsappId } });

    if (data.info) {
      setNoMessagesFlag(true);
    }
    else {
      setNoMessagesFlag(false);
      setHasMore(data.hasMore);

      setMessagesList(previousValue => {
        if (data.messages) return [...data.messages, ...previousValue];
        return [...previousValue];
      });

      setFilesExistenceDictionary({ ...filesExistenceDictionary, ...data.filesExistenceDictionary});
    }

    setLoading(false);
  };

  const handleScroll = async (event) => {
    if (!hasMore || loading) return;

    let { scrollTop } = event.currentTarget;
    
    if (scrollTop === 0) document.getElementById("messagesList").scrollTop = 1;
    
    if (scrollTop < 800) {
      await loadMessages(pageNumber + 1);
      setPageNumber((previousValue) => previousValue + 1);
    }
  };

  const handleOpenPhoneNumberMessageOptionsMenu = (event, phoneNumber, isMsgFromMe) => {
    const targetClassName = isMsgFromMe ? "messageRight" : "messageLeft";
    const parentNodeElement = getParentNodeByClassName(targetClassName, event.currentTarget);

    setPhoneNumberMessageOptionsMenuAnchorEl(parentNodeElement);
    setSelectedPhoneNumberMessage(phoneNumber);
  };

  const handleClosePhoneNumberMessageOptionsMenu = () => {
    setPhoneNumberMessageOptionsMenuAnchorEl(null);
  };

  const handleOpenPrivateChatConversationModal = (selectedContact) => {
    setSelectedContactPrivateChat(selectedContact);
    setOpenPrivateChatConversationModal(true);
  };

  const handleClosePrivateChatConversationModal = () => {
    setSelectedContactPrivateChat(null);
    setOpenPrivateChatConversationModal(false);
    setSelectedWhatsappId("");
  };

  const handleOpenPrivateChat = async (selectedContactId) => {
    if (!selectedContactId) return;
    setLoading(true);
    if (!selectedWhatsappId || selectedWhatsappId === undefined || selectedWhatsappId === "") {
      toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    }
    else {
      try {
        const { data: ticket } = await api.post("/tickets", {
          contactId: selectedContactId,
          userId: userId,
          status: "open",
          isCreatedByUser: true,
          whatsappId: selectedWhatsappId
        });
        history.push(`/tickets/${ticket.id}`);
      } catch (error) {
        console.log("Handle Open Private Chat Error:", error);
        toastError(error);
      }
    }

    setLoading(false);
  };

  const handleOpenPollVotesModal = (message) => {
    setSelectedPollVotesMessage(message);
    setPollVotesModalOpen(true);
  };

  const handleClosePollVotesModal = () => { 
    setPollVotesModalOpen(false);
    setSelectedPollVotesMessage([]);
  };

  const handleOpenMessageEditHistoryModal = (message) => {
    setMessageEditHistoryModalOpen(true);
    setSelectedMessageEditHistory(message);
  };

  const handleCloseMessageEditHistoryModal = () => {
    setMessageEditHistoryModalOpen(false);
    setSelectedMessageEditHistory(null);
  };

  const checkMessageMedia = (message, isQuotedMessage=false) => {   
    if (["application", "audio", "image", "ptv", "sticker", "video"].includes(message.mediaType) && !filesExistenceDictionary[message.mediaUrl]) {
      return (<MessageNotAvailable />);
    }
    
    else if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    
    else if (message.mediaType === "button") { 
      const buttonMessage = message.body;
      const buttonMessageTitleFooter = buttonMessage.split("##");
      const buttonMessageArray = buttonMessageTitleFooter[0].split("||");
      const buttonTitle = buttonMessageTitleFooter[1];
      const buttonFooter = buttonMessageTitleFooter[2];

      const buttonBody = buttonMessageArray[0];
      const buttonOptionsArray = buttonMessageArray.slice(1);

      return(
        <>
          <b>{buttonTitle}</b>
          <p>{buttonBody}</p>
          <i className={classes.buttonListFooter}>{buttonFooter}</i>
          <hr />
          <center>
            {buttonOptionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                disabled
              >
                {option}
              </Button>
            ))}
          </center>
        </>
      );

    }
    
    else if (message.mediaType === "list") { 
      const listMessage = message.body;
      const listMessageArray = listMessage.split("||");

      const headerArray = listMessageArray[0].split("%%");
      const optionsArray = listMessageArray.slice(1);
      return (
        <>
          <b>{headerArray[0]}</b>
          <p>{headerArray[1]}</p>
          <i className={classes.buttonListFooter}>{headerArray[3]}</i>
          <center>
            <Button
              startIcon={<FormatListBulletedOutlined />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
              disabled
            >
              {headerArray[2]}
            </Button>
          </center>
          
          <hr />

          <center>
            {optionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                href={message.mediaUrl}
                disabled
              >
                {option.split("%%")[0]}
              </Button>
            ))}
          </center>
        </>
      );
    }
    
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("waid=") !== -1) {
            obj.push({ number: values[ind].split("waid=")[1] });
          }

          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} enableHandleNewChat={true} />
    }
    
    else if (message.mediaType === "multi_vcard") {
      const vcardsLimiter = "\n----vcardLimiter----\n";
      const vcards = message.body.split(vcardsLimiter).filter(vcard => vcard !== "");
      const vcardsElements = [];
      const contactsList = [];

      for (let vcardIndex = 0; vcardIndex < vcards.length; vcardIndex += 1) {
        vcardsElements.push(vcards[vcardIndex].split("\n"));
      }

      for (let vcardElementIndex = 0; vcardElementIndex < vcardsElements.length; vcardElementIndex += 1) {
        try {
          contactsList.push({
            number: vcardsElements[vcardElementIndex][4].split("waid=")[1].split(":")[0],
            name: vcardsElements[vcardElementIndex][3].split(":")[1],
          })
        } catch (err) {
          try {
            contactsList.push({
              number: vcardsElements[vcardElementIndex][6].split("waid=")[1].split(":")[0],
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            })
          } catch (err) {
            contactsList.push({
              number: undefined,
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            });
          }
        }
      }

      return <MultiVcardPreview contacts={contactsList} enableStartChat={true} />;
    }
    
    else if (message.mediaType === "image" && isQuotedMessage && !message.isViewOnce) {
      return (
        <ModalImageCors
          imageUrl={message.mediaUrl}
          replyMessage={true}
          isDeleted={message.isDeleted}
        />
      );
    }
    
    else if (message.mediaType === "image" && !isQuotedMessage && !message.isViewOnce) {
      return (
        <ModalImageCors
          imageUrl={message.mediaUrl}
          replyMessage={false}
          isDeleted={message.isDeleted}
        />
      );
    }
    
    else if (message.mediaType === "image" && isQuotedMessage && message.isViewOnce) {
      return <ViewOnceRepliedMessage isPhoto={true} />
    }
    
    else if (message.mediaType === "image" && !isQuotedMessage && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={true} />
    }
    
    else if (message.mediaType === "sticker") {
      return (
        <img
          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
          src={message.mediaUrl} alt={message.body} width={225} height={225}
        />
      );
    }
    
    else if (message.mediaType === "audio") {
      return (
        <Audio
          currentAudioRef={currentAudioRef}
          transcript={message.transcript}
          url={message.mediaUrl}
          ticket={message.ticket}
          messageId={message.id}
          isDeleted={message.isDeleted}
          isVoice={message.isVoice}
          isFromMe={message.fromMe}
        />
      );
    }
    
    else if (message.mediaType === "video" && !message.isViewOnce) {
      let mediaClass = isFullscreen
        ? `${classes.messageMediaFullScreen}`
        : `${classes.messageMedia}`;

      if (message.isDeleted) mediaClass += ` ${classes.deletedMediaMessage}`;

      return (
        <video
          className={mediaClass}
          src={message.mediaUrl}
          controls={!message.isGif}
          autoPlay={message.isGif}
          muted={message.isGif}
          loop={message.isGif}
        />
      );
      
    }
    
    else if (message.mediaType === "video" && isQuotedMessage && message.isViewOnce) {
      return <ViewOnceRepliedMessage isPhoto={false} />
    }
    
    else if (message.mediaType === "video" && !isQuotedMessage && message.isViewOnce) {
      return <ViewOnceMessage message={message} isPhoto={false} />
    }
    
    else if (message.mediaType === "ptv") {
      return (
        <Videoplayer size={200} src={message.mediaUrl} boundaryColor={"#ff6961"} />
      );
    }
    
    else if (message.mediaType === "poll_creation" && !isQuotedMessage && !message.isRating) {
      return <PollPreview message={message} handleOpenPollVotesModal={handleOpenPollVotesModal} readonly={true} />
    }
    
    else if (message.mediaType === "poll_creation" && !isQuotedMessage && message.isRating) {
      return <PollPreview message={message} handleOpenPollVotesModal={handleOpenPollVotesModal} readonly={true} />
    }
    
    else if (message.mediaType === "poll_creation" && isQuotedMessage) {
      return <PollPreviewQuotedMessage message={message} />
    }

    else if (message.mediaType === "event" && !isQuotedMessage) {
      return <EventPreview message={message} />;
    }

    else if (message.mediaType === "event" && isQuotedMessage) {
      return <EventPreviewQuotedMessage message={message} />;
    }
    
    else if (message.mediaType === "group_invite") {
      return <GroupInvitePreview message={message} isQuotedMessage={isQuotedMessage} />
    }
    
    else {
      const mediaExtension = message.mediaUrl.split(".")[message.mediaUrl.split(".").length - 1];
      const handleClick = (event) => {
        event.preventDefault();
        saveFile(message);
      }

      return (
        <>
          <div className={message.isDeleted ? `${classes.downloadMedia} ${classes.deletedMediaMessage}` : classes.downloadMedia}>
            {!isSmallScreen && !isQuotedMessage && mediaExtension === "pdf" && (
              <embed src={message.mediaUrl} width="500" height="375" type="application/pdf" />
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "txt" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/plain"/>
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "md" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/markdown"/>
            )}

            <Button
              startIcon={<GetApp />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href="#"
              onClick={handleClick}
              className={classes.floatingButton}
            >{i18n.t("messagesInput.download")}</Button>

            {isQuotedMessage && message.body}
          </div>
          <Divider />
        </>
      );
    }
  };

  const checkInteractiveMessage = (message) => {
    if (message.mediaType === "list_creation") {
      return (<ListPreview message={message} />);
    } else if (message.mediaType === "button_creation") {
      return (<ButtonPreview message={message} />);
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1 || message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderEndTicket = (message) => {
    if (message.isLastMessage && message.ticket.type === 0) {
      return (
        <>
          <br />
          <div className={classes.lastMessageTicketContainer}>
            {message.ticket.user?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (${message.ticket.user?.name}) (${message.ticket.whatsapp?.name}) ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}

            {!message.ticket.user?.name && message.ticket.directClosedByUser?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (${message.ticket.directClosedByUser?.name}) (${message.ticket.whatsapp?.name})  ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}

            {!message.ticket.user?.name && !message.ticket.directClosedByUser?.name && (
              <>
              <hr className={classes.lastMessageTicketDivider} />
              <span className={classes.lastMessageTicketSpan}>
                {`${i18n.t("messagesListLabels.ticketEnd")} #${message.ticketId} (-) (${message.ticket.whatsapp?.name}) ${message.ticket.isClosedAt ? `(${extractDayMonthYearFromStringDateTime(message.ticket.isClosedAt)})` : ""}`}
              </span>
              <hr className={classes.lastMessageTicketDivider} />
              </>
            )}
          </div>
          <br />
        </>
      );
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }

    if (index <= messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
  };

  const renderDivToAutomaticScroll = (message, index) => {
    if (index === messagesList.length - 1) {
      return (<div key={`ref-${message.createdAt}`} ref={lastMessageRef} style={{ float: "left", clear: "both" }} />);
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    // Quoted Poll Messages are not shown when user is not admin and
    // poll is rating
    if (userProfile === "user" && message.quotedMsg?.isRating) return <></>
    return (
      <a className={classes.quotedMessageAnchor} href={`#messageContainer-${message.quotedMsg.id}`}>
        <div className={clsx(classes.quotedContainerLeft, {[classes.quotedContainerRight]: message.fromMe})}>
          <span className={clsx(classes.quotedSideColorLeft, {[classes.quotedSideColorRight]: message.quotedMsg?.fromMe})}></span>

          <div className={classes.quotedMsg}>
            {!message.quotedMsg?.fromMe && (
              <span className={classes.messageContactName}>
                {message.quotedMsg?.contact?.name}
              </span>
            )}

            {message.quotedMsg?.mediaType !== "list" 
            && message.quotedMsg?.mediaType !== "button" 
            && message.quotedMsg?.mediaType !== "vcard" 
            && message.quotedMsg?.mediaType !== "multi_vcard"
            && message.quotedMsg?.mediaType !== "image" 
            && message.quotedMsg?.mediaType !== "sticker"
            && message.quotedMsg?.mediaType !== "audio" 
            && message.quotedMsg?.mediaType !== "video" 
            && message.quotedMsg?.mediaType !== "location" 
            && message.quotedMsg?.mediaType !== "application" 
            && message.quotedMsg?.mediaType !== "poll_creation"
            && message.quotedMsg?.mediaType !== "event"
            && message.quotedMsg?.mediaType !== "ptv"
            && message.quotedMsg?.mediaType !== "group_invite"
            && (
              <MessageBodyWrapper
                messageBody={message.quotedMsg?.body}
                isMessageFromMe={message.fromMe}
                handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
              />
            )}

            {message.quotedMsg?.mediaType === "button" && (
              <>
                <b>{message.quotedMsg?.body.split("##")[1]}</b>
                <p>{message.quotedMsg?.body.split("||")[0]}</p>
                <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("##")[2]}</i>
              </>
            )}

            {message.quotedMsg?.mediaType === "list" && (
              <>
                <b>{message.quotedMsg?.body.split("||")[0].split("%%")[0]}</b>
                <p>{message.quotedMsg?.body.split("||")[0].split("%%")[1]}</p>
                <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("||")[0].split("%%")[3]}</i>
              </>
            )}

            {(message.quotedMsg?.mediaType === "vcard" 
            || message.quotedMsg?.mediaType === "multi_vcard"
            || message.quotedMsg?.mediaType === "image"
            || message.quotedMsg?.mediaType === "sticker"
            || message.quotedMsg?.mediaType === "audio"
            || message.quotedMsg?.mediaType === "video"
            || message.quotedMsg?.mediaType === "location"
            || message.quotedMsg?.mediaType === "poll_creation"
            || message.quotedMsg?.mediaType === "event"
            || message.quotedMsg?.mediaType === "ptv"
            || message.quotedMsg?.mediaType === "application"
            || message.quotedMsg?.mediaType === "group_invite") && checkMessageMedia(message.quotedMsg, true)}
          </div>
        </div>
      </a>
    );
  };

  const renderGroupNotification = (message) => {
    return (
      <span className={classes.notificationSpan} key={message.id}>
        <div className={classes.dailyTimestampText}>{message.body}</div>
      </span>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message.ack !== 9) {
          //  **************************
          //  ** Notification Message **
          //  **************************
          if (message.mediaType === "notification") {
            return (
              <React.Fragment key={message.key}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderGroupNotification(message)}
              </React.Fragment>
            );
          }

          //  **********************
          //  ** Internal Message **
          //  **********************
          else if (["internal", "transference_observation"].includes(message.mediaType)) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}

                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={`${classes.messageRight} ${classes.internalMessage}`}>
                    <div
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                    
                      {message.mediaType !== "list" 
                      && message.mediaType !== "poll_creation"
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}

                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                            {i18n.t("messagesInput.editedMessage") + " "}
                          </span>
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  </div>

                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                  && message.user.name && message.user.name !== undefined
                  && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }

          //  *************************
          //  ** Rating Poll Message **
          //  *************************
          //
          // - Message is not shown when user is not admin and message
          // is a rating poll
          //
          else if (userProfile === "user" && message.isRating) {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }
          

          //  *******************************************
          //  ** Message Sent By Contact - Non-Sticker **
          //  *******************************************
          else if (!message.fromMe && message.mediaType !== "sticker") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}
                
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageLeft}`}>
                  {/* 
                    ******************************
                    ** Group Member Profile Pic **                  
                    ******************************
                  */}
                  {message.ticket.status === "group" && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.contact?.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.contact?.name}
                          src={message.contact?.profilePicUrl}
                          className={classes.contactAvatar}
                          onClick={() => handleOpenPrivateChatConversationModal(message.contact)}
                        />
                      </Tooltip>
                    </div>
                  )}



                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageLeft}>                   
                    {message.ticket.isGroup && (
                      <span className={classes.messageContactName}>
                        {message.contact?.name}
                      </span>
                    )}

                    {message.quotedMsg && (
                      <div 
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    {(message.mediaUrl 
                    || message.mediaType === "location" 
                    || message.mediaType === "vcard"
                    || message.mediaType === "multi_vcard"
                    || message.mediaType === "poll_creation"
                    || message.mediaType === "event"
                    || message.mediaType === "group_invite"
                    ) && checkMessageMedia(message)}
                    
                    
                    {/* 
                      ***************
                      ** TimeStamp **
                      ***************
                    */}
                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      {message.isForwarded && (
                        <>
                          <i className={classes.forwardedMessage}>
                            <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                          </i>
                          <br />
                        </>
                      )}

                      {message.mediaType !== "call"
                      && message.mediaType !== "poll_creation" 
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.mediaType !== "location"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}

                      {(message.mediaType === "audio"
                      || message.mediaType === "image"
                      || message.mediaType === "video"
                      || !message.isGif)
                      && (<br />)}

                      {(message.adsSourceUrl || message.adsTitle || message.adsThumbnailUrl)
                        && <AdsMessage message={message} redirectFlag={true} />}

                      {message.mediaType === "call" && message.isVideoCall && (
                        <div className={classes.callWrapper}>
                          <MissedVideoCallRounded className={classes.missedCall} /> 
                          <MessageBodyWrapper
                            messageBody={message.body}
                            isMessageFromMe={message.fromMe}
                            handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                          />
                        </div>
                      )}

                      {message.mediaType === "call" && !message.isVideoCall && (
                        <div className={classes.callWrapper}>
                          <PhoneMissedRounded className={classes.missedCall} />
                          <MessageBodyWrapper
                            messageBody={message.body}
                            isMessageFromMe={message.fromMe}
                            handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                          />
                        </div>
                      )}

                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                              {i18n.t("messagesInput.editedMessage") + " "}
                            </span>
                        )}

                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                    

                    {/* 
                      ***************
                      ** Reactions **
                      ***************
                    */}
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionList}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.contactReaction}
                            </span>
                          </Tooltip>
                        )}

                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.fromMeReaction}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* 
                  **************************
                  ** Interactive Messages **
                  **************************
                */}
                {(message.mediaType !== "list_creation" || message.mediaType !== "button_creation") && (
                  checkInteractiveMessage(message)
                )}
                {renderEndTicket(message)}
              </React.Fragment>
            );
          } 
          
          

          //  ***************************************
          //  ** Message Sent By Contact - Sticker **
          //  ***************************************
          else if (!message.fromMe && message.mediaType === "sticker") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}

                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageLeft}`}>
                  {/* 
                    ******************************
                    ** Group Member Profile Pic **                  
                    ******************************
                  */}
                  {message.ticket.status === "group" && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.contact?.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.contact?.name}
                          src={message.contact?.profilePicUrl}
                          className={classes.contactAvatar}
                          onClick={() => handleOpenPrivateChatConversationModal(message.contact)}
                        />
                      </Tooltip>
                    </div>
                  )}



                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageLeftSticker}>
                    {message.isForwarded && (
                      <>
                        <i className={classes.forwardedMessage}>
                          <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                        </i>
                        <br />
                      </>
                    )}

                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    {!filesExistenceDictionary[message.mediaUrl]
                      ? (
                        <>
                          <MessageNotAvailable />
                          <br />
                        </>
                      )
                      : (
                        <img
                          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
                          src={message.mediaUrl} alt={message.body} width={225} height={225}
                        />
                      )
                    }
                    
                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted,})}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      <span className={`${classes.timestamp} ${classes.timestampStickerLeft}`}>
                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                      </span>
                    </div>
                    
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionListSticker}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.contactReaction}</span>
                          </Tooltip>
                        )}

                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.fromMeReaction}</span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }

          
          
          //  **************************************
          //  ** Message Sent By Me - Non-Sticker **
          //  **************************************
          else if (message.fromMe && message.mediaType !== "sticker") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}

                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageRight}>                    
                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}



                    {(message.mediaUrl 
                    || message.mediaType === "location" 
                    || message.mediaType === "vcard" 
                    || message.mediaType === "multi_vcard"
                    || message.mediaType === "list" 
                    || message.mediaType === "button"
                    || message.mediaType === "poll_creation"
                    || message.mediaType === "event"
                    || message.mediaType === "group_invite"
                    ) && checkMessageMedia(message)}

                    <div
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted })}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >
                      {message.isForwarded && (
                        <>
                          <i className={classes.forwardedMessage}>
                            <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                          </i>
                          <br />
                        </>
                      )}

                      {message.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}

                      {(message.adsSourceUrl || message.adsTitle || message.adsThumbnailUrl)
                      && <AdsMessage message={message} redirectFlag={true} />}

                      {message.mediaType !== "list" 
                      && message.mediaType !== "poll_creation"
                      && message.mediaType !== "event"
                      && message.mediaType !== "group_invite"
                      && message.mediaType !== "multi_vcard"
                      && message.mediaType !== "vcard"
                      && message.mediaType !== "location"
                      && message.body !== ""
                      && (
                        <MessageBodyWrapper
                          messageBody={message.body}
                          isMessageFromMe={message.fromMe}
                          handleOpenPhoneNumberMessageOptionsMenu={handleOpenPhoneNumberMessageOptionsMenu}
                        />
                      )}

                      {(message.mediaType === "audio"
                      || message.mediaType === "image"
                      || message.mediaType === "video"
                      || !message.isGif)
                      && (<br />)}

                      <span className={classes.timestamp}>
                        {message.isEdited && (
                          <span 
                            className={classes.editedMessage}
                            onClick={() => handleOpenMessageEditHistoryModal(message)}
                          >
                            {i18n.t("messagesInput.editedMessage") + " "}
                          </span>
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>

                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionList}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.contactReaction}
                            </span>
                          </Tooltip>
                        )}

                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>
                              {message.fromMeReaction}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                  
                  

                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                  && message.user.name && message.user.name !== undefined
                  && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>

                {/* 
                  **************************
                  ** Interactive Messages **
                  **************************
                */}
                {(message.mediaType !== "list_creation" || message.mediaType !== "button_creation") && (
                  checkInteractiveMessage(message)
                )}

                {renderEndTicket(message)}
              </React.Fragment>
            );
          }



          //  **********************************
          //  ** Message Sent By Me - Sticker **
          //  **********************************
          else if (message.fromMe && message.mediaType === "sticker") {
            return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderDivToAutomaticScroll(message, index)}
                {renderMessageDivider(message, index)}
                
                <div id={`messageContainer-${message.id}`} className={`${classes.containerMessage} ${classes.containerMessageRight}`}>
                  {/* 
                    *************
                    ** Message **
                    *************
                  */}
                  <div className={classes.messageRightSticker}>
                    {message.isForwarded && (
                      <>
                        <i className={classes.forwardedMessage}>
                          <ReplyOutlined className={classes.horizontalFlip} />{i18n.t("messagesInput.forwardedMessage")}
                        </i>
                        <br />
                      </>
                    )}

                    {message.quotedMsg && (
                      <div
                        className={classes.textContentItem}
                        style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                      >
                        {renderQuotedMessage(message)}
                      </div>
                    )}

                    {!filesExistenceDictionary[message.mediaUrl]
                      ? (
                        <>
                          <MessageNotAvailable />
                          <br />
                        </>
                      )
                      : (
                        <img
                          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
                          src={message.mediaUrl} alt={message.body} width={225} height={225}
                        />
                      )
                    }

                    <div 
                      className={clsx(classes.textContentItem, {[classes.textContentItemDeleted]: message.isDeleted,})}
                      style={message.isEdited ? {padding: "3px 80px 1em 6px"} : {padding: "3px 80px 6px 6px"}}
                    >

                      <span className={`${classes.timestamp} ${classes.timestampStickerRight}`}>
                        {message.isDeleted && (
                          <Block fontSize="small" className={classes.deletedIcon} />
                        )}

                        {message.isStarred && (
                          <StarOutlined className={classes.timeStampStar} />
                        )}

                        {message.isBroadcast && (
                          <RecordVoiceOverRounded className={classes.timeStampStar} />
                        )}

                        {message.isStatusReply && (
                          <FilterTiltShiftOutlined className={classes.timeStampStar} />
                        )}

                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                    
                    {(message.contactReaction || message.fromMeReaction) && (
                      <div className={classes.reactionListSticker}>
                        {message.contactReaction && (
                          <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.contactReaction}</span>
                          </Tooltip>
                        )}

                        {message.fromMeReaction && (
                          <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                            <span className={classes.reactionSpan}>{message.fromMeReaction}</span>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>



                  {/* 
                    **********************
                    ** User Profile Pic **
                    **********************
                  */}
                  {message.user && message.user !== undefined
                  && message.user.name && message.user.name !== undefined
                  && (
                    <div className={classes.contactAvatarContainer}>
                      <Tooltip title={message.user.name} placement="top-start" arrow>
                        <Avatar
                          alt={message.user.name}
                          src={message.user.name}
                          className={classes.contactAvatar}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                {renderEndTicket(message)}
              </React.Fragment>
            );
          }
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };
  

  

  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.messagesListWrapper}>
      <PhoneNumberMessageOptionsMenu
        anchorEl={phoneNumberMessageOptionsMenuAnchorEl}
        handleClose={handleClosePhoneNumberMessageOptionsMenu}
        menuOpen={phoneNumberMessageOptionsMenuOpen}
        phoneNumber={selectedPhoneNumberMessage}
      />

      <PollVotesModal
        open={pollVotesModalOpen}
        onClose={handleClosePollVotesModal}
        message={selectedPollVotesMessage}
      />

      <MessageEditHistoryModal
        open={messageEditHistoryModalOpen}
        onClose={handleCloseMessageEditHistoryModal}
        message={selectedMessageEditHistory}
      />

      <ConfirmationModal
        title={selectedContactPrivateChat && `${i18n.t("messagesList.confirmationModal.openPrivateChat.title")} ${selectedContactPrivateChat.name}?`}
        open={openPrivateChatConversationModal}
        onClose={handleClosePrivateChatConversationModal}
        onConfirm={() => handleOpenPrivateChat(selectedContactPrivateChat.id)}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(e) => setSelectedWhatsappId(e.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsApps.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ConfirmationModal>

      
      <div id="messagesList" className={classes.messagesList} onScroll={handleScroll}>
        {messagesList.length > 0 ? renderMessages() : []}

        {noMessagesFlag && (
          <div className={classes.noMessageContainer}>
            <Lottie
              options={chatLottieOptions}
              isClickToPauseDisabled={true}
              className={classes.noMessageLottie}
              style={{
                width: "50.00%",
                minWidth: "175px",
                maxWidth: "250px",

                height: "80.00%",
                minHeight: "175px",
                maxHeight: "250px",
              }}
            />

            <span className={classes.notificationSpan}>
              <div className={classes.noMessagesText}>{i18n.t("messagesListChatView.noMessagesText")}</div>
            </span>
          </div>
        )}
      </div>

      {loading && (<div><CircularProgress className={classes.circleLoading} /></div>)}
    </div>
  );
};

export default MessagesListChatView;
