import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlined } from "@material-ui/icons";
import ReactImgEditor from "@cloudogu/react-img-editor";
import "@cloudogu/react-img-editor/lib/index.css";

import { i18n } from "../../translate/i18n";
import { getPixelRatioFromCanvas } from "../../utils/getPixelRatioFromCanvas";
import "../../styles/react-img-editor-new-styles.css";

const useStyles = makeStyles(theme => ({
  //  *************
  //  ** Content **
  //  *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles },



  //  ********************
  //  ** Dialog Actions **
  //  ********************
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
    margin: "1em",
  },

  floatingButton: {
    transition: "all 0.3s ease",
    "&:hover": { transform: "translateY(-5px)" },
  },
}));

const FileContextEditImageModal = ({
  open, onClose, imageUrl,
  medias, setMedias, mediaUrlIndex
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const stageRef = useRef(null);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { onClose(); };

  const setComponentStage = (stage) => { stageRef.current = stage; };

  const handleConfirmEdition = () => {
    const canvas = stageRef.current.clearAndToCanvas({ pixelRatio: getPixelRatioFromCanvas(stageRef) });

    canvas.toBlob((blob) => {
      const updatedMedias = [...medias];
      updatedMedias[mediaUrlIndex] = new File([blob], medias[mediaUrlIndex].name, { type: medias[mediaUrlIndex].type });
      setMedias(updatedMedias);
    }, medias[mediaUrlIndex].type);

    onClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <span>{i18n.t("editImageModal.title.up")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <ReactImgEditor
            language="en"
            src={imageUrl}
            crossOrigin="anonymous"
            getStage={setComponentStage}
            toolbar={{
              items: ["pen", "eraser", "arrow", "rect", "circle", "mosaic", "text"
            , "|", "repeal", "download", "crop"]
            }}
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.floatingButton}
            startIcon={<CancelOutlined />}
            color="inherit"
            variant="outlined"
            onClick={handleClose}
          >{i18n.t("editImageModal.buttons.cancel")}</Button>

          <Button
            className={classes.floatingButton}
            startIcon={<CheckCircleOutlined />}
            color="inherit"
            variant="outlined"
            onClick={handleConfirmEdition}
          >{i18n.t("editImageModal.buttons.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileContextEditImageModal;
