import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
	Button,
  CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import DispatchManagerAdditionalContactsModal from "../DispatchManagerAdditionalContactsModal";
import extractMonthFromStringDateTime from "../../utils/extractMonthFromStringDateTime";
import LabelSelect from "../LabelSelect";
import TransferList from "../TransferList";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "1em",
  },

  filterContainer: {
    width: "100.00%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  textInputField: {
		width: "250px",
		"& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary },
	},

  transferContainer: {
    width: "100.00%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonsContainer: {
    width: "100.00%",
    display: "flex",
    flexDiretion: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  btnWrapper: { position: "relative", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  buttonProgress: { color: green[500] },
}));

const DispatchManagerContactsModal = ({ open, onClose }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setFieldValue, values } = useFormikContext();

  const [loading, setLoading] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("theme"));
  const [birthday, setBirthday] = useState("");
  const [labelsList, setLabelsList] = useState([]);
  const [dispatchManagerAdditionalContactsModalOpen, setDispatchManagerAdditionalContactsModalOpen] = useState(false);

  const [nonSelectedContactsList, setNonSelectedContactsList] = useState([]);
  const [selectedContactsList, setSelectedContactsList] = useState([]);

  const [searchNameNumber, setSearchNameNumber] = useState("");
  const [searchBirthday, setSearchBirthday] = useState("");
  const [searchAttribute, setSearchAttribute] = useState([]);  



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) {
      const fetchContacts = async () => {
        setLoading(true);

        // ***---- Fetching All Contacts ----***
        const apiCalls = [
          api.get("/AllContacts", { params: { shouldConsiderGroups: 0 } }),
          api.get("/label")
        ];

        const [ContactsResponse, LabelsResponse] = await Promise.all(apiCalls);

        // ***---- Formatting Contacts ----***
        const formattedContacts = ContactsResponse.data.contacts.filter(contact => {
          const phoneNumber = contact.number;
          const phoneNumberLength = phoneNumber.length;
          if (![12, 13].includes(phoneNumberLength)) return false;

          const ddd = parseInt(phoneNumber.substring(2, 4), 10);
          const verificationDigit = parseInt(phoneNumber.substring(4, 5), 10);

          const isPhoneNumberValidCondition1 = ddd > 30 && phoneNumberLength === 12;
          const isPhoneNumberValidCondition2 = ddd <= 30 && verificationDigit === 9 && phoneNumberLength === 13;
          const isPhoneNumberValidCondition3 = ddd <= 30 && verificationDigit !== 9 && phoneNumberLength === 12;
          const isPhoneNumberValid = isPhoneNumberValidCondition1 || isPhoneNumberValidCondition2 || isPhoneNumberValidCondition3;
          return isPhoneNumberValid;
        }).map(contact => ({
          id: contact.id,
          description: contact.name,
          subDescription: contact.number,
          attribute: contact.labels.map(label => ({ id: label.id, name: label.name })),
          subAttribute: contact.birthday
            ? extractMonthFromStringDateTime(`${contact.birthday.slice(0, 10)} 00:00:00`)
            : ""
        }));

        setNonSelectedContactsList(
          formattedContacts.filter(contact => !values.dispatchContacts.includes(contact.id))
        );

        setSelectedContactsList(
          formattedContacts.filter(contact => values.dispatchContacts.includes(contact.id))
        );

        // ***---- Formatting Labels ----***
        const formattedLabels = LabelsResponse.data.map(label => ({ id: label.id, name: label.name }));
        setLabelsList(formattedLabels);

        setLoading(false);
      };

      fetchContacts();

      setSelectedTheme(localStorage.getItem("theme"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setNonSelectedContactsList([]);
    setSelectedContactsList([]);
    setSearchNameNumber("");
    setSearchBirthday("");
    setSearchAttribute([]);
    setBirthday("");
    setLabelsList([]);
    setDispatchManagerAdditionalContactsModalOpen(false);
    setLoading(false);
  };

  const handleOpenDispatchManagerAdditionalContactsModal = () => {
    setDispatchManagerAdditionalContactsModalOpen(true);
  };

  const handleCloseDispatchManagerAdditionalContactsModal = () => {
    setDispatchManagerAdditionalContactsModalOpen(false);
  };

  const handleSearchNameNumber = (event) => {
    setSearchNameNumber(event.target.value.toLowerCase());
  };

  const handleSearchBirthday = (event) => {
    const newBirthdayValue = event.target.value;

    setBirthday(newBirthdayValue);
    
    setSearchBirthday(
      newBirthdayValue === ""
        ? newBirthdayValue
        : extractMonthFromStringDateTime(`${newBirthdayValue}-01 00:00:00`)
    );
  };

  const handleSearchAttribute = (values) => {
    setSearchAttribute(values);
  };

  const validateContacts = (valuesToValidate) => {
    let areContactsValid = true;

    // ***---- Contacts ----***
    const areContactsInvalid = valuesToValidate.length < 3 && values.additionalContacts.length < 3;

    if (areContactsInvalid) {
      areContactsValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.contactsSize"));
    }

    return areContactsValid;
  };

  const removeDuplicatedContacts = (selectedContactsList, additionalContacts) => {
    additionalContacts = additionalContacts.filter(
      additionalContact => !selectedContactsList.includes(additionalContact)
    );

    setFieldValue("additionalContacts", additionalContacts);
  };

  const handleSaveContacts = () => {
    // ***---- Preparing Data ----***
    const dispatchContactsValue = selectedContactsList.map(contact => contact.id);

    setFieldValue("dispatchContacts", dispatchContactsValue);

    // ***---- Validating Selected Contacts ----***
    const areContactsValid = validateContacts(dispatchContactsValue);

    // ***---- Removind Duplicated Contacts ----***
    const selectedContactsNumberList = selectedContactsList.map(contact => contact.subDescription);

    removeDuplicatedContacts(selectedContactsNumberList, values.additionalContacts);

    // ***---- Closing Modal ----***
    if (areContactsValid) handleClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <DispatchManagerAdditionalContactsModal
        open={dispatchManagerAdditionalContactsModalOpen}
        onClose={handleCloseDispatchManagerAdditionalContactsModal}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" scroll="paper" fullWidth>
        <DialogTitle>
          {i18n.t("dispatchManagerContactsModal.title")}
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          {/* 
            *****************
            ** Filter List **
            *****************
          */}
          <div className={classes.filterContainer}>
            <TextField
              className={classes.textInputField}
              fullWidth={isSmallScreen ? true : false}
              label={i18n.t("dispatchManagerContactsModal.filters.nameNumber")}
              margin="dense"
              variant="outlined"
              value={searchNameNumber}
              onChange={handleSearchNameNumber}
              autoFocus
            />

            <TextField
              type="month"
              className={classes.textInputField}
              fullWidth={isSmallScreen ? true : false}
              label={i18n.t("dispatchManagerContactsModal.filters.birthday")}
              margin="dense"
              variant="outlined"
              value={birthday}
              onChange={handleSearchBirthday}
              InputLabelProps={{ shrink: true }}
              style={{ "color-scheme": selectedTheme, }}
            />

            <LabelSelect
              selectedLabelIds={searchAttribute}
              onChange={values => handleSearchAttribute(values)}
              labelsOptions={labelsList}
              styleSelectInputField={true}
            />
          </div>

          {/* 
            *******************
            ** Transfer List **
            *******************
          */}
          <div className={classes.transferContainer}>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            
            <TransferList
              nonSelectedItemsList={nonSelectedContactsList}
              setNonSelectedItemsList={setNonSelectedContactsList}
              selectedItemsList={selectedContactsList}
              setSelectedItemsList={setSelectedContactsList}

              searchParam={searchNameNumber}
              searchAttribute={searchAttribute}
              searchSubAttribute={searchBirthday}
            />
          </div>

          {/* 
            *************************
            ** Additional Contacts **
            *************************
          */}
          <div className={classes.buttonsContainer}>
            <Button
              onClick={handleOpenDispatchManagerAdditionalContactsModal}
              color="inherit"
              variant="outlined"
              className={classes.floatingButton}
            >
              {i18n.t("dispatchManagerContactsModal.buttons.additionalContacts")}
            </Button>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("dispatchManagerContactsModal.buttons.cancel")}
          </Button>

          <Button
            onClick={handleSaveContacts}
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
          >
            {i18n.t("dispatchManagerContactsModal.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DispatchManagerContactsModal;
