import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchOutlined";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  chips: { display: "flex", flexWrap: "wrap"},

  chip: {
    margin: 2,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
  },

  chipCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': { color: theme.palette.primary.main },
  },

  selectInputField: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
  },
}));

const ContactSelect = ({ selectedContactIds, onChange, contactsOptions, styleSelectInputField=true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [searchTextContact, setSearchTextContact] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/AllContacts", { params: { shouldConsiderGroups: 1 } });
        setContacts(data.contacts);
      } catch (error) {
        console.log("Contact Select Use Effect Error:", error);
        toastError(error);
      }
    })();
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChange = e => {
    onChange(e.target.value[0] === undefined ? [] : e.target.value);
  };

  const containsText = (text="", searchText) => {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };



  //  ***********
  //  ** Memos **
  //  ***********
  const displayedContactOptions = useMemo(
    () => contactsOptions.filter(option => containsText(option.name, searchTextContact)),
    [contactsOptions, searchTextContact]
  );

  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
      >
        <InputLabel>{i18n.t("contactSelect.inputLabel")}</InputLabel>
        <Select
          multiple
          label={i18n.t("contactSelect.inputLabel")}
          value={selectedContactIds}
          onChange={handleChange}
          onClose={() => setSearchTextContact("")}
          MenuProps={{
            autoFocus: false,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 &&
                selected.map(id => {
                  const contact = contacts.find(c => c.id === id);
                  return contact 
                    ? (<Chip key={id} variant="outlined" label={contact.name} className={classes.chip} />)
                    : null;
                })
              }
            </div>
          )}
        >
          <ListSubheader disableSticky>
            <TextField
              autoFocus
              fullWidth
              size="small"
              placeholder={i18n.t("contactSelect.searchTextPlaceholder")}
              style={{ marginTop: "1em" }}
              onChange={(event) => setSearchTextContact(event.target.value)}
              onKeyDown={(event) => { if (event.key !== "Escape") { event.stopPropagation(); } }}
              inputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>) }}
            />
          </ListSubheader>
          
          {displayedContactOptions.map(contact => (
            <MenuItem key={contact.id} value={contact.id}>
              <Checkbox className={classes.chipCheckbox} checked={selectedContactIds.indexOf(contact.id) > -1} />
              <ListItemText primary={contact.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ContactSelect;