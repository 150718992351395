import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import DispatchManagerContactsModal from "../DispatchManagerContactsModal";
import DispatchManagerMessagesModal from "../DispatchManagerMessagesModal";
import formatDefaultValueForDatetimeInput from "../../utils/formatDefaultValueForDatetimeInputs";
import toastError from "../../errors/toastError";
import useAllWhatsAppsDispatch from "../../hooks/useAllWhatsAppsDispatch";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },

  fieldWithPersonalizedIcon: {
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary },
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  btnWrapper: { position: "relative", },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const DispatchManagerModal = ({
  open, onClose, dispatchId, handleOpenLoadingDispatchModal, handleCloseLoadingDispatchModal
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { loading, whatsapps } = useAllWhatsAppsDispatch();

  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("theme"));

  const initialState = {
    name: "",
    datetimeToDispatch: formatDefaultValueForDatetimeInput(new Date(new Date().getTime() + 7200 * 1000)), // 120 minutes in milliseconds
    recurrence: "once",
    whatsappId: "",
    dispatchContacts: [],
    additionalContacts: [],
    dispatchMessages: { message1: "", message2: "", message3: "" }
  };
  
  const [dispatch, setDispatch] = useState(initialState);
  
  const [contactsModalOpen, setContactsModalOpen] = useState(false);
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchDispatch = async () => {
      if (!open) return;
      if (!dispatchId) return;

      try {
        // ***---- Fetching Dispatch Data ----***
        const { data } = await api.get(`/dispatch/${dispatchId}`);

        // ***---- Transforming 'datetimeToDispatch' and 'whatsappId' ----***
        data.datetimeToDispatch = formatDefaultValueForDatetimeInput(new Date(data.datetimeToDispatch));
        data.whatsappId = whatsapps.find(whatsapp => whatsapp.id === data.whatsappId) ? data.whatsappId : "";

        // ***---- Transforming 'dispatchContacts' ----***
        const dispatchContacts = [...data.dispatchContacts];

        data.dispatchContacts = dispatchContacts
          .filter(dispatchContact => ![null, undefined].includes(dispatchContact.contactId))
          .map(dispatchContact => dispatchContact.contactId);

        data.additionalContacts = dispatchContacts
          .filter(dispatchContact => ![null, undefined].includes(dispatchContact.additionalContact))
          .map(dispatchContact => dispatchContact.additionalContact);

        // ***---- Transfoming 'dispatchMessages' ----***
        const dispatchMessages = { message1: "", message2: "", message3: "" };
        data.dispatchMessages.forEach((value, index) => {
          dispatchMessages[`message${index+1}`] = value.body;
        });
        data.dispatchMessages = dispatchMessages;

        // ***---- Setting State ----***
        setDispatch(previousValue => { return { ...previousValue, ...data }; });
      } catch (exception) {
        console.log("DispatchManagerModal Use Effect 1 Exception:", exception);
        toastError(exception);
      }
    };

    fetchDispatch();

    setSelectedTheme(localStorage.getItem("theme"));
  }, [open, dispatchId, whatsapps]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setTimeout(() => { setDispatch(initialState); }, 100);
  };

  const handleOpenContactsModal = () => {
    setContactsModalOpen(true);
  };

  const handleCloseContactsModal = () => {
    setContactsModalOpen(false);
  };

  const handleOpenMessagesModal = () => {
    setMessagesModalOpen(true);
  };

  const handleCloseMessagesModal = () => {
    setMessagesModalOpen(false);
  };

  const validateDispatchValues = (values) => {
    let areValuesValid = true;

    // ***---- Name ----***
    if (values.name.length < 3 || values.name.length > 50) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.nameSize"));
    }

    // ***---- Datetime to Dispatch
    if (values.datetimeToDispatch === "") {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.datetimeToDispatchEmpty"));
    }

    const currentDatetimePlusOneHour = new Date(new Date().getTime() + 3600 * 1000); // 60 minutes in milliseconds
    if (new Date(values.datetimeToDispatch) <= currentDatetimePlusOneHour) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.datetimeToDispatchInvalid"));
    }
    
    // ***---- Recurrence ----***
    if (!["once", "weekly", "monthly"].includes(values.recurrence)) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.recurrenceInvalid"));
    }

    // ***---- Connection ----***
    if ([null, undefined].includes(values.whatsappId)) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.connectionInvalid"));
    }

    // ***---- Messages ----***
    const isMessage1Invalid = values.dispatchMessages.message1.length < 3 || values.dispatchMessages.message1.length > 500;
    const isMessage2Invalid = values.dispatchMessages.message2 !== "" && (values.dispatchMessages.message2.length < 3 || values.dispatchMessages.message2.length > 500);
    const isMessage3Invalid = values.dispatchMessages.message3 !== "" && (values.dispatchMessages.message3.length < 3 || values.dispatchMessages.message3.length > 500);

    if (isMessage1Invalid) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.message1Size"));
      setMessagesModalOpen(true);
    }

    if (isMessage2Invalid) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.otherMessagesSize"));
      setMessagesModalOpen(true);
    }
    
    if (isMessage3Invalid) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.otherMessagesSize"));
      setMessagesModalOpen(true);
    }

    // ***---- Contacts ----***
    const areContactsInvalid = values.dispatchContacts.length < 3 && values.additionalContacts.length < 3;

    if (areContactsInvalid) {
      areValuesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.contactsSize"));
      setContactsModalOpen(true);
    }

    // Return
    return areValuesValid;
  };

  const handleSaveDispatch = async (values) => {
    const isDispatchValuesValid = validateDispatchValues(values);

    if (isDispatchValuesValid) {
      try {
        const loadingModalTitleText = dispatchId ? "updating" : "creating";
        handleOpenLoadingDispatchModal(loadingModalTitleText);

        if (dispatchId) await api.put(`/dispatch/${dispatchId}`, values);
        else await api.post("/dispatch", values);

        handleCloseLoadingDispatchModal();
        handleClose();
      } catch (exception) {
        handleCloseLoadingDispatchModal();
        
        console.log("HandleSaveDispatch Exception:", exception);
        toastError(exception);
      }
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          {dispatchId ? i18n.t("dispatchManagerModal.title.edit") : i18n.t("dispatchManagerModal.title.add")}
        </DialogTitle>

        <Formik
          initialValues={dispatch}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              await handleSaveDispatch(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {/* 
                  ************
                  ** Modals **
                  ************
                */}
                <DispatchManagerContactsModal open={contactsModalOpen} onClose={handleCloseContactsModal} />
                <DispatchManagerMessagesModal open={messagesModalOpen} onClose={handleCloseMessagesModal} />

                {/* 
                  **********
                  ** Name **
                  **********
                */}
                <Field
                  as={TextField}
                  label={i18n.t("dispatchManagerModal.form.name")}
                  autoFocus
                  autoComplete="off"
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                  InputProps={{ inputProps: { minLength: 3, maxLength: 50 } }}
                />

                {/* 
                  **************************
                  ** Datetime To Dispatch **
                  **************************
                */}
                <Field
                  as={TextField}
                  type="datetime-local"
                  label={i18n.t("dispatchManagerModal.form.datetimeToDispatch")}
                  autoComplete="off"
                  name="datetimeToDispatch"
                  error={touched.datetimeToDispatch && Boolean(errors.datetimeToDispatch)}
                  helperText={touched.datetimeToDispatch && errors.datetimeToDispatch}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  style={{ "color-scheme": selectedTheme, }}
                />

                {/* 
                  ****************
                  ** Recurrence **
                  ****************
                */}
                <br />
                <br />
                <Divider />
                <Divider />
                {/* <br /> */}
                
                {/* 
                  - Recurrence Disabled: only enables it when it be necessary to add more recurrence
                types such as 'weekly' and 'monthly.
                  For now, only 'once' recurrence is enabled.
                */}
                {/*<FormControl variant="outlined" margin="dense" required fullWidth>
                  <InputLabel>{i18n.t("dispatchManagerModal.form.recurrence")}</InputLabel>

                  <Field
                    as={Select}
                    label={i18n.t("dispatchManagerModal.form.recurrence")}
                    name="recurrence"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    className={classes.fieldWithPersonalizedIcon}
                  >
                    <MenuItem key="once" value="once">{i18n.t("dispatchManagerModal.form.recurrenceValues.once")}</MenuItem>
                    <MenuItem key="weekly" value="weekly">{i18n.t("dispatchManagerModal.form.recurrenceValues.weekly")}</MenuItem>
                    <MenuItem key="monthly" value="monthly">{i18n.t("dispatchManagerModal.form.recurrenceValues.monthly")}</MenuItem>
                  </Field>
                </FormControl> */}

                {/* 
                  ****************
                  ** Connection **
                  ****************
                */}
                {!loading && (
                  <>
                    <br />
                    <FormControl variant="outlined" margin="dense" required fullWidth>
                      <InputLabel>{i18n.t("dispatchManagerModal.form.connection")}</InputLabel>

                      <Field
                        as={Select}
                        label={i18n.t("dispatchManagerModal.form.connection")}
                        name="whatsappId"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        className={classes.fieldWithPersonalizedIcon}
                      >
                        <MenuItem value={''}>&nbsp;</MenuItem>
                        {whatsapps.map(whatsapp => (
                          <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </>
                )}

                {/* 
                  *************************
                  ** Contacts & Messages **
                  *************************
                */}
                <br />
                <br />
                <Divider />
                <br />

                <div className={classes.buttonsContainer}>
                  <Button
                    onClick={handleOpenContactsModal}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.floatingButton}
                  >
                    {i18n.t("dispatchManagerModal.form.buttons.contacts")}
                  </Button>

                  <Button
                    onClick={handleOpenMessagesModal}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.floatingButton}
                  >
                    {i18n.t("dispatchManagerModal.form.buttons.messages")}
                  </Button>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="inherit"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.floatingButton}
                >
                  {i18n.t("dispatchManagerModal.buttons.cancel")}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.btnWrapper} ${classes.floatingButton}`}
                >
                  {dispatchId ? i18n.t("dispatchManagerModal.buttons.okEdit") : i18n.t("dispatchManagerModal.buttons.okAdd")}
                  {isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} />)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default DispatchManagerModal;
