import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import IconFieldAndFolder from "../../assets/file_and_folder.png";
import IconPlus from "../../assets/plus.png";
import truncateString from "../../utils/truncateString";

const useStyles = makeStyles((theme) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
    padding: "1.5em",
    background: theme.backgroundImage,
  },



  //  ********************************
  //  ** Single File View Container **
  //  ********************************
  fileViewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  fileView: {
    width: "100%",
    borderRadius: "5px",
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  fileIcon: {
    width: "100px",
    filter: "grayscale(.9)",
    opacity: "0.9",
    userDrag: "none",
  },

  multipleFilesIcon: {
    width: "50px",
    filter: "grayscale(.9)",
    opacity: "0.9",
    userDrag: "none",
  },

  fileSpan: {
    color: theme.palette.text.primary,
    textAlign: "center",
    opacity: "0.9",
  },

  fileName: {
    opacity: "0.9",
    textAlign: "center",
    marginTop: "1em",
  },



  //  ***********************************
  //  ** Multiple Files View Container **
  //  ***********************************
  multipleFilesViewContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  multipleFilesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    width: "100px",
    height: "125px",
    borderRadius: "20px",
    border: "2px solid red",

    cursor: "pointer",
    transition: "all 0.3s ease",

    "&:hover": {
      transform: "translateY(-5px)",
      filter: "brightness(0.75)",
    },
  },

  removeFileContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },

  removeFileIconButton: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    width: "17px",
    height: "17px",
    top: "-8px",
    left: "-3px",
    "&:hover": { backgroundColor: theme.palette.primary.main },
  },

  removeFileButton: {
    width: "17px",
  },

  actionButton: {
    color: theme.palette.text.primary,
  },

  secondaryMultipleFilesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
  },




  //  *************
  //  ** Buttons **
  //  *************
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)" },
  },
}));

const AutomaticMessagesMediasModal = ({ open, onClose }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setFieldValue, values }  = useFormikContext();

  const [tempMedias, setTempMedias] = useState([]);
  const [mediasTreatment, setMediasTreatment] = useState([]);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaUrlIndex, setMediaUrlIndex] = useState(0);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) setTempMedias([...values.medias]);
  }, [open, values.medias]);

  useEffect(() => {
    if (open && tempMedias && tempMedias.length > 0) { setMediaUrl(URL.createObjectURL(tempMedias[0])); }
  }, [open, tempMedias]);

  useEffect(() => {
    return () => { URL.revokeObjectURL(mediaUrl); };
  }, [mediaUrl]);

  useEffect(() => {
    if (open && tempMedias && tempMedias.length > 0) {
      const checkMediasTreatment = () => {
        const treatments = [];

        for (let index = 0; index < tempMedias.length; index++) {
          //
          // Codes:
          //
          // - 1 > pdf (embed tag)
          // - 2 > txt (embed tag)
          // - 3 > md (embed tag)
          // - 4 > image (img tag)
          // - 5 > video (video tag)
          // - 6 > audio (audio tag)
          // - 7 > other file type (general visualization)
          try {
            const mediaType = tempMedias[index].type.split("/")[0];

            if (isSmallScreen) { treatments.push(7); }
            else if (tempMedias[index].type === "application/pdf") { treatments.push(1); }
            else if (tempMedias[index].type.includes("text/plain")) { treatments.push(2); }
            else if (tempMedias[index].name.split(".")[tempMedias[index].name.split(".").length - 1] === "md") { treatments.push(3); }
            else if (mediaType === "image") { treatments.push(4); }
            else if (mediaType === "video") { treatments.push(5); }
            else if (mediaType === "audio") { treatments.push(6); }
            else { treatments.push(7); }
          } catch (exception) {
            treatments.push(7);
          }
        }

        setMediasTreatment(treatments);
      };

      checkMediasTreatment();
    }
  }, [open, tempMedias, isSmallScreen]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    handleClose();
    setTempMedias([]);
    setMediasTreatment([]);
    setMediaUrl("");
    setMediaUrlIndex(0);
  };

  const handleConfirm = () => {
    const notAllowedFormats = ["ts", "js", "sh"];
    
    for (let index = 0; index < tempMedias.length; index ++) {
      if (notAllowedFormats.includes(tempMedias[index].name.split(".")[1])) toast.info(i18n.t("backendErrors.ERR_FILE_NOT_SUPPORTED"));
      else if (tempMedias[index].size > 8000000 * 15) toast.info(i18n.t("backendErrors.ERR_FILE_SIZE_UPLOAD"));
      else setFieldValue("medias", [...tempMedias]);
    }

    handleClose();
  };

  const handleChangeFileView = (index) => {
    setMediaUrl(URL.createObjectURL(tempMedias[index]));
    setMediaUrlIndex(index);
  };

  const handleRemoveFile = (index) => {
    tempMedias.splice(index, 1);

    const tempMediasTreatment = mediasTreatment.slice();
    tempMediasTreatment.splice(index, 1);
    setMediasTreatment(tempMediasTreatment);

    if (tempMedias && tempMedias.length > 0) {
      setMediaUrlIndex(0);
      setMediaUrl(URL.createObjectURL(tempMedias[0]));
    }
    else {
      setFieldValue("medias", []);
    }
  };

  const renderMediasTreatment = (media, index) => {
    return (
      <Tooltip title={media.name} placement="top-start" arrow>
        <div className={classes.multipleFilesView}>
          <div className={classes.removeFileContainer}>
            <IconButton
              className={classes.removeFileIconButton}
              size="small"
              onClick={() => handleRemoveFile(index)}
            >
              <ClearOutlined className={`${classes.actionButton} ${classes.removeFileButton}`} />
            </IconButton>
          </div>

          <div className={classes.secondaryMultipleFilesView} onClick={() => handleChangeFileView(index)}>
            <div className={classes.fileView}>
              <img className={classes.multipleFilesIcon} src={IconFieldAndFolder} alt="Icon" />
            </div>

            {mediasTreatment[index] !== 0 && (
              <span className={classes.fileName}>
                {truncateString(media.name, 10)}
              </span>
            )}
          </div>
        </div>
      </Tooltip>
    );
  };

  const handleAddMoreFiles = (event) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    setTempMedias([...tempMedias, file]);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={open} maxWidth="xs" scroll="paper" fullWidth>
      <DialogTitle>
        <span>{i18n.t("automaticMessagesMediasModal.title.up")}</span>
      </DialogTitle>

      <div className={classes.dialogContent}>
        {/* 
          **********************
          ** Single File View **
          **********************
        */}
        <div className={classes.fileViewContainer}>
          {/* ***---- Visualizations ----*** */}
          {mediasTreatment[mediaUrlIndex] === 1 && (<embed className={classes.fileView} src={mediaUrl} type="application/pdf" />)}
          {mediasTreatment[mediaUrlIndex] === 2 && (<embed className={classes.fileView} src={mediaUrl} type="text/plain" />)}
          {mediasTreatment[mediaUrlIndex] === 3 && (<embed className={classes.fileView} src={mediaUrl} type="text/markdown" />)}

          {mediasTreatment[mediaUrlIndex] === 4 && (<img className={classes.fileView} alt="📁" src={mediaUrl} />)}
          {mediasTreatment[mediaUrlIndex] === 5 && (<video className={classes.fileView} controls><source src={mediaUrl} type={tempMedias[0].type} /></video>)}
          {mediasTreatment[mediaUrlIndex] === 6 && (<audio controls><source src={mediaUrl} type={tempMedias[0].type} /></audio>)}

          {mediasTreatment[mediaUrlIndex] === 7 && (
            <div className={classes.fileView}>
              <img className={classes.fileIcon} src={IconFieldAndFolder} alt="Icon" />
              <span className={classes.fileSpan}>{i18n.t("automaticMessagesMediasModal.noVisualizationMessage")}</span>
            </div>
          )}
        </div>



        {/* 
          *************************
          ** Multiple Files View **
          *************************
        */}
        <div className={classes.multipleFilesViewContainer}>
          {tempMedias && tempMedias.length > 0 && tempMedias.map((media, index) => (
            renderMediasTreatment(media, index)
          ))}

          {tempMedias && tempMedias.length < 5 && (
            <>
              <input
                type="file"
                id="upload-more-file"
                style={{ display: "none" }}
                onChange={handleAddMoreFiles}
              />

              <Tooltip title={i18n.t("automaticMessagesMediasModal.addFile")} placement="top-start" arrow>
                <label className={classes.multipleFilesView} htmlFor="upload-more-file">
                  <img className={classes.multipleFilesIcon} src={IconPlus} alt="Icon" />
                </label>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <DialogActions>
        <Button
          onClick={handleCancel}
          color="inherit"
          variant="outlined"
          className={classes.floatingButton}
        >
          {i18n.t("automaticMessagesMediasModal.buttons.cancel")}
        </Button>

        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          className={`${classes.btnWrapper} ${classes.floatingButton}`}
        >
          {i18n.t("automaticMessagesMediasModal.buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutomaticMessagesMediasModal;
